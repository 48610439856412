const headerHeight = 72;
const headerHeightSm = 64;

export const mainFooterHeight = {
    lg: 200,
    md: 200,
    sm: 200,
    xs: 200,
};
export const secondaryFooterHeight = {
    lg: 110,
    md: 110,
    sm: 131,
    xs: 207,
};
export const cookieBannerHeight = {
    lg: 72,
    md: 72,
    sm: 92,
    xs: 120,
    xxs: 160,
};

const breadcrumbHeight = 70;
const pageTopPadding = 70;
const pageBottomPadding = 25;

export const page = {
    padding: {
        top: pageTopPadding,
        bottom: pageBottomPadding,
    },
    breadcrumbs: {
        height: breadcrumbHeight,
    },
    footer: {
        cookieBannerHeight,
        mainFooterHeight,
        secondaryFooterHeight,
    },
    header: {
        height: headerHeight,
        heightSm: headerHeightSm,
    },
    welcome: {
        navOpacity: 0.8,
        backgroundOpacity: 0.2,
    },
};
