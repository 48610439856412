import React, { useEffect, Suspense, lazy } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { useMediaQuery } from 'react-responsive';
import { values, compose, map, isEmpty } from 'ramda';
import { ThemeProvider } from 'styled-components';
import { CookiesProvider } from 'react-cookie';
import { clientRoutes, ONBOARDING, REDIRECT_PARAMS, REWARDSHOP_URL, REWARDSHOP, REDIRECT_URL } from '@constants';
import { configSelector, isAuthSelector } from '@services/auth/selectors';
import { loadConfigRequest } from '@services/config/actions';
import routes from '@constants/routes';
import { getItem, removeItem, setItem } from '@utils/storageUtils';
import { loadUserRequest } from '@services/user/actions';
import { brandingSelector } from '@services/branding/selectors';
import { footerIsVisibleSelector, footerSettingsSelector } from '@services/settings/selectors';
import { userSelector } from '@services/user/selectors';
import { locationSelector } from '@services/selectors';
import { parseLink, staticResources, openLogin } from '@utils/link';
import Notifier from '@components/Notifier';
import { toApiFormat } from '@utils/date';
import { checkUserIsValid, getUserId } from '@utils/user';
import { getUri } from '@services/uris/api';
import { LINK_NAME_SHOP } from '@constants/apiParams';
import { NotistackProvider } from 'src/common/containers/NoticeProvider';
import { sizes } from 'src/theme/vars/sizes';
import PrivateRoute from 'src/common/containers/Route';
import Welcome from 'src/routes/Welcome';
import GlobalStyles from 'src/GlobalStyles';
import { getTheme } from 'src/theme';
import { addGeocoderScript } from '@utils/geocoder';
import { loadTermsOfUseCheckRequest } from '@services/stipulations/actions';
import { userStipulationsToAgree } from '@services/stipulations/selectors';
import { useMfa } from '@hooks/useMfa';
import { loadingIndicatorSelector } from '@services/indicator/selectors';
import Wrapper from './Wrapper';

const Footer = lazy(() => import('@containers/Footer'));
const Navbar = lazy(() => import('@containers/Navbar'));

const signupRoute = clientRoutes.signup();
const phoneRoute = clientRoutes.phone();
const twoFactorAuthRoute = clientRoutes.twoFactorAuth();
const onboardingRoute = clientRoutes.onboarding();
const confirmPrivacyRoute = clientRoutes.confirmPrivacy();
const subscriptionRoute = clientRoutes.subscription();
const additionalConsentRoute = clientRoutes.additionalConsent();
const confirmEmailRoute = clientRoutes.confirmEmail();
const validRoutes = [onboardingRoute, '/'];

const IS_DEEP_LINK = true;
const ROUTES_WITHOUT_NAVBAR = [clientRoutes.discover()];

const Routes = compose(
    map(route => <PrivateRoute key={route.path()} {...route} path={route.path()} />),
    values,
)(routes);

const shouldCompleteOnboarding = (isValidUser, isUserLoggedIn, location) =>
    !isValidUser && isUserLoggedIn && !validRoutes.includes(location.pathname);

const shouldUpdateSubscription = (user, isAuthUser) => {
    const currentDate = toApiFormat(new Date());
    const codeInvalid = !user.subscriptionModel || user.subscriptionModel?.invalidFrom <= currentDate;
    return isAuthUser && codeInvalid;
};
const shouldConfirmPrivacy = (isAuthUser, stipulationsToAgree) => isAuthUser && !isEmpty(stipulationsToAgree);

const getDeepLink = location => {
    const deepLink = parseLink(location.pathname, IS_DEEP_LINK);
    const getter = staticResources[deepLink.action] && staticResources[deepLink.action][deepLink?.collection];
    return getter && getter(deepLink.id);
};

const shouldConfirmPhoneNumber = (user, isAuthUser) => isAuthUser && !user.phoneNumberConfirmed;

const shouldAcceptConsent = (config, stipulationsToAgree) => {
    const { enabled, categories } = config?.features?.additionalConsent || {};
    if (!enabled) {
        return false;
    }

    if (categories?.length) {
        return !isEmpty(stipulationsToAgree.filter(({ category }) => categories.includes(category)));
    }

    return !isEmpty(stipulationsToAgree);
};

const shouldConfirmEmailAddress = (user, isAuthUser, location) =>
    isAuthUser && !user.emailConfirmed && !validRoutes.includes(location.pathname);

export default () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isFooterVisible = useSelector(footerIsVisibleSelector);
    const footerSettings = useSelector(footerSettingsSelector);
    const isAuth = useSelector(isAuthSelector);
    const branding = useSelector(brandingSelector);
    const user = useSelector(userSelector);
    const location = useSelector(locationSelector);
    const config = useSelector(configSelector);
    const loading = useSelector(loadingIndicatorSelector);
    const stipulationsToAgree = useSelector(userStipulationsToAgree);
    const isStipulationsLoading = loading.check;
    const { showCompanyInformation } = config;
    const needsPhoneConfirmation = config?.features?.phone?.needsConfirmation;
    const showCounty = config?.features?.country?.showCounty;
    const { useSSO } = config?.features?.login || {};
    const shouldVerify2FA = useMfa(user, isAuth);
    const isValidUser = checkUserIsValid(user, showCompanyInformation, showCounty, isAuth);

    const needsEmailConfirmation = config?.features?.signup?.needsEmailConfirmation;
    const theme = getTheme(branding?.color);
    const linkTo = getDeepLink(location);
    const redirectUrl = getItem(REWARDSHOP_URL);
    const redirectParams = getItem(REDIRECT_PARAMS);
    const isLoggedIn = isAuth && Object.keys(user).length > 0;
    const isRewardShopLink = location.pathname.includes(REWARDSHOP);
    const redirectPrivateUrl = getItem(REDIRECT_URL);

    const validateUserRouteChange = location => {
        const isOnboarding = getItem(ONBOARDING);

        if (shouldVerify2FA) {
            if (!redirectPrivateUrl) {
                setItem([REDIRECT_URL], [location.pathname]);
            }
            return dispatch(push(twoFactorAuthRoute));
        }

        if (needsPhoneConfirmation && shouldConfirmPhoneNumber(user, isLoggedIn)) {
            return dispatch(push(phoneRoute));
        }

        if (isOnboarding && isLoggedIn && shouldAcceptConsent(config, stipulationsToAgree)) {
            return dispatch(push(additionalConsentRoute));
        }

        if (shouldUpdateSubscription(user, isLoggedIn)) {
            return dispatch(replace(subscriptionRoute));
        }

        if (shouldConfirmPrivacy(isLoggedIn, stipulationsToAgree) && !isOnboarding) {
            return dispatch(replace(confirmPrivacyRoute));
        }

        if (shouldCompleteOnboarding(isValidUser, isLoggedIn, location)) {
            return dispatch(push(onboardingRoute));
        }

        if (needsEmailConfirmation && shouldConfirmEmailAddress(user, isLoggedIn, location)) {
            return dispatch(push(confirmEmailRoute));
        }

        if (location.pathname.includes('/onboarding') && location.search.includes('code=')) {
            return dispatch(replace(`${signupRoute}${location.search}`));
        }

        if (location.pathname.includes(twoFactorAuthRoute) && !shouldVerify2FA) {
            return dispatch(push('/'));
        }

        if (location.pathname.includes(phoneRoute) && user.phoneNumberConfirmed) {
            return dispatch(push('/'));
        }

        if (location.pathname.includes(confirmEmailRoute) && user.emailConfirmed) {
            return dispatch(push('/'));
        }

        if (location.pathname.includes(additionalConsentRoute) && !shouldAcceptConsent(config, stipulationsToAgree)) {
            return dispatch(push('/'));
        }

        if (isRewardShopLink) {
            setItem([REWARDSHOP_URL], [true]);
            if (location.search) {
                setItem([REDIRECT_PARAMS], [location.search.replace(/\?/g, '&')]);
            }
            if (!isAuth) {
                return openLogin(dispatch, useSSO);
            }
        }

        if (redirectPrivateUrl && isLoggedIn && !isStipulationsLoading && isValidUser) {
            removeItem(REDIRECT_URL);
            return dispatch(push(redirectPrivateUrl));
        }

        return null;
    };

    useEffect(() => {
        const userId = getUserId();
        if (userId && isEmpty(user)) {
            dispatch(loadUserRequest(userId));
        }
        if (isLoggedIn && !shouldVerify2FA) {
            dispatch(loadTermsOfUseCheckRequest());
        }
        dispatch(loadConfigRequest());
    }, [shouldVerify2FA, isLoggedIn]);

    useEffect(() => {
        if (redirectUrl && isLoggedIn && !shouldVerify2FA) {
            getUri(LINK_NAME_SHOP).then(data => {
                const url = redirectParams ? `${data?.data?.uri}${redirectParams}` : data?.data?.uri;
                removeItem(REWARDSHOP_URL);
                removeItem(REDIRECT_PARAMS);
                window.open(url);
            });
        }
    }, [isLoggedIn, redirectUrl, shouldVerify2FA]);

    useEffect(() => {
        if (!user) return;
        validateUserRouteChange(location);
    }, [
        config,
        isAuth,
        user,
        stipulationsToAgree,
        location.pathname,
        shouldVerify2FA,
        isStipulationsLoading,
        isValidUser,
    ]);

    useEffect(() => {
        if (!isEmpty(config)) {
            addGeocoderScript(config);
        }
    }, [config]);

    const isMobile = useMediaQuery({ maxWidth: sizes.sm - 1 });
    const hideNavbar = isMobile && !isAuth && ROUTES_WITHOUT_NAVBAR.some(route => location.pathname.includes(route));

    return (
        <ThemeProvider theme={theme}>
            <NotistackProvider>
                <Suspense fallback="">
                    <Wrapper $isFooterVisible={isFooterVisible} $footerSettings={footerSettings}>
                        {hideNavbar ? null : <Navbar />}
                        <Notifier />
                        <Switch>
                            <Route exact path="/" component={Welcome} />
                            {Routes}
                            <Redirect from="/onboarding" to="/signup" />
                            <Redirect to={linkTo || clientRoutes.me()} />
                        </Switch>
                        <GlobalStyles />
                    </Wrapper>
                    <CookiesProvider>
                        <Footer />
                    </CookiesProvider>
                </Suspense>
            </NotistackProvider>
        </ThemeProvider>
    );
};
