import React from 'react';
import { FormattedMessage } from 'react-intl';
import { handleActions } from 'redux-actions';
import * as userActionTypes from '@services/user/actionTypes';
import { getUserId } from '@utils/user';
import { setMomentLocale } from '@utils/date';
import * as types from './actionTypes';

const codeError = <FormattedMessage id="ACCESS_CODE_VALIDATION" />;
const credentialsError = <FormattedMessage id="INVALID_CREDENTIALS" />;
const passwordError = <FormattedMessage id="PASSWORD_IS_INCORRECT" />;
const couponCodeError = <FormattedMessage id="ENTER_VALID_COUPON" />;

export const initialState = {
    isAuth: !!getUserId(),
    resetUrl: '',
    error: '',
    code: {
        valid: false,
        error: '',
        stipulations: [],
    },
    credentials: {
        error: '',
    },
    couponCode: {
        valid: false,
        error: '',
    },
    is2FACodeResendEnabled: false,
    otpStatus: {
        sendAttemptsSize: null,
        sendControlPeriod: null,
        sends: null,
        lastSendAttempt: null,
    },
};

export default handleActions(
    {
        [types.UPDATE_2FA_PHONENUMBER_SUCCESS]: (state, action) => {
            const { sendAttemptsSize = null, sendControlPeriod = null, sends = null, lastSendAttempt = null } =
                action.payload.otpStatus || {};
            return {
                ...state,
                otpStatus: { sendAttemptsSize, sendControlPeriod, sends, lastSendAttempt },
            };
        },
        [types.LOAD_2FA_CODE_SUCCESS]: (state, action) => {
            const { sendAttemptsSize = null, sendControlPeriod = null, sends = null, lastSendAttempt = null } =
                action.payload.otpStatus || {};
            return {
                ...state,
                otpStatus: { sendAttemptsSize, sendControlPeriod, sends, lastSendAttempt },
            };
        },
        [types.LOAD_2FA_CODE_STATUS_SUCCESS]: (state, action) => {
            const { sendAttemptsSize = null, sendControlPeriod = null, sends = null, lastSendAttempt = null } =
                action.payload.otpStatus || {};
            return {
                ...state,
                otpStatus: { sendAttemptsSize, sendControlPeriod, sends, lastSendAttempt },
            };
        },
        [types.LOAD_LOGIN_SUCCESS]: (state, action) => {
            const user = action.payload;
            setMomentLocale(user.language);

            const { sendAttemptsSize = null, sendControlPeriod = null, sends = null, lastSendAttempt = null } =
                action.payload.otpStatus || {};
            return {
                ...state,
                isAuth: true,
                otpStatus: { sendAttemptsSize, sendControlPeriod, sends, lastSendAttempt },
            };
        },
        [types.LOAD_LOGIN_FAILURE]: state => ({
            ...state,
            credentials: {
                error: credentialsError,
            },
        }),
        [userActionTypes.LOAD_USER_SUCCESS]: (state, action) => {
            const user = action.payload;
            return {
                ...state,
            };
        },
        [types.LOAD_RESET_PASSWORD_SUCCESS]: (state, action) => ({
            ...state,
            resetUrl: action.payload.resetUrl,
        }),
        [types.LOGOUT]: state => ({
            ...state,
            resetUrl: '',
            error: '',
        }),
        [types.LOGOUT_SUCCESS]: state => ({
            ...state,
            isAuth: false,
        }),
        [types.LOAD_CODE_SUCCESS]: (state, action) => ({
            ...state,
            code: {
                valid: action.payload.valid,
                error: action.payload.valid ? '' : codeError,
                stipulations: action.payload.stipulations,
            },
        }),
        [types.LOAD_CODE_FAILURE]: state => ({
            ...state,
            code: {
                ...state.code,
                error: codeError,
            },
        }),
        [types.CLEAR_ACCESS_CODE]: state => ({
            ...state,
            code: {
                stipulations: [],
                valid: false,
            },
        }),
        [types.UPDATE_PASSWORD_FAILURE]: state => ({
            ...state,
            credentials: {
                error: passwordError,
            },
        }),
        [types.CLEAR_ERROR_FIELD]: (state, action) => ({
            ...state,
            [action.payload]: {
                error: '',
            },
        }),
        [types.LOAD_COUPON_CODE_SUCCESS]: (state, action) => ({
            ...state,
            couponCode: {
                valid: action.payload.valid,
                error: action.payload.valid ? '' : couponCodeError,
            },
        }),
        [types.LOAD_COUPON_CODE_FAILURE]: state => ({
            ...state,
            couponCode: {
                error: couponCodeError,
            },
        }),
        [types.ENABLE_2FA_CODE_RESEND]: state => ({
            ...state,
            is2FACodeResendEnabled: true,
        }),
        [types.DISABLE_2FA_CODE_RESEND]: state => ({
            ...state,
            is2FACodeResendEnabled: false,
        }),
    },
    initialState,
);
