import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { push } from 'connected-react-router';
import useMatomoScript from '@hooks/useMatomoScript';
import { configSelector, isAuthSelector } from '@services/auth/selectors';
import { loadingIndicatorSelector } from '@services/indicator/selectors';
import { clientRoutes, WELCOME, REACTIVATION_MODAL } from '@constants';
import { generatePageTitle } from '@utils';
import { setItem } from '@utils/storageUtils';
import { openSignup } from '@utils/link';
import { getUserLocale } from '@utils/locale';
import { modalSelector } from '@services/modal/selectors';
import { closeModalRequest } from '@services/modal/actions';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import bg1 from 'assets/img/1.png';
import bg2 from 'assets/img/2.png';
import bg3 from 'assets/img/3.png';
import bg4 from 'assets/img/4.png';
import bg5 from 'assets/img/5.png';
import {
    Background,
    DotsBox,
    NavigationBox,
    StyledCarousel,
    Wrapper,
    Dot,
    PrevButton,
    NextButton,
    ArrowIcon,
    Illustration,
    MainIllustration,
} from 'src/routes/Welcome/styled';
import Page from './Page';
import { SignBtn } from './styled';
import ReactivationModal from './ReactivationModal';

const PAGES = [
    {
        background: bg1,
        title: 'welcome_screen_title_welcome',
        body: 'welcome_screen_text_welcome',
        illustration: <MainIllustration id="il_welcome_logo" title={'welcome_screen_title_welcome'} />,
        skipCircles: true,
    },
    {
        background: bg2,
        title: 'SLIDE_TITLE_1',
        body: 'SLIDE_BODY_1',
        illustration: <Illustration id="il_welcome_hs" title={'SLIDE_TITLE_1'} />,
    },
    {
        background: bg3,
        title: 'welcome_screen_title_tracking',
        body: 'SLIDE_BODY_2',
        illustration: <Illustration id="il_welcome_navigator" title={'welcome_screen_title_tracking'} />,
    },
    {
        background: bg4,
        title: 'SLIDE_TITLE_3',
        body: 'SLIDE_BODY_3',
        illustration: <Illustration id="il_welcome_trophy" title={'SLIDE_TITLE_3'} />,
    },
    {
        background: bg5,
        title: 'welcome_screen_title_privacy',
        body: 'welcome_screen_text_privacy',
        illustration: <Illustration id="il_welcome_privacy" title={'welcome_screen_title_privacy'} />,
    },
];

const MIN_SLIDE = 0;
const MAX_SLIDE = PAGES.length - 1;
const FORWARD = 1;
const BACKWARD = -1;
const MIN_SWIPE_DISTANCE = 70;

const redirectAuthorized = (isAuth, dispatch) => {
    if (isAuth) {
        dispatch(push(clientRoutes.me()));
    }
};

const CallMatomoUnauthorized = () => {
    useMatomoScript(WELCOME);
    return null;
};

const Welcome = () => {
    const isAuth = useSelector(isAuthSelector);
    const dispatch = useDispatch();
    const intl = useIntl();
    const modal = useSelector(modalSelector);
    const config = useSelector(configSelector);
    const { config: configIsLoading } = useSelector(loadingIndicatorSelector);
    const useSSO = config.features?.login?.useSSO;
    const [currentSlide, setCurrentSlide] = useState(MIN_SLIDE);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const BACK_ARROW_LABEL = intl.formatMessage({ id: 'ARROW_BACK_LABEL' });
    const NEXT_ARROW_LABEL = intl.formatMessage({ id: 'ARROW_FORWARD_LABEL' });
    const SLIDER_DOT_LABEL = number => intl.formatMessage({ id: 'SLIDER_DOT_LABEL' }, { number });
    const SLIDER_LABEL = intl.formatMessage({ id: 'SLIDER_LABEL' });
    generatePageTitle(WELCOME);
    setItem(['LOCALE'], [getUserLocale(config.supportedLocales)]);

    useEffect(() => {
        redirectAuthorized(isAuth, dispatch);
    }, [isAuth]);

    const handleArrowClick = index => () => {
        const nextSlide = currentSlide + index;

        if (nextSlide < MIN_SLIDE) {
            return setCurrentSlide(MAX_SLIDE);
        }

        const value = nextSlide > MAX_SLIDE ? MIN_SLIDE : nextSlide;
        return setCurrentSlide(value);
    };

    const getContent = useCallback(
        () =>
            PAGES.map((page, index) => (
                <Wrapper key={index}>
                    <Background src={page.background}>
                        <Page {...page} selected={index === currentSlide} />
                        <NavigationBox aria-label={SLIDER_LABEL} tabIndex="0">
                            <SignBtn
                                tabIndex={index === currentSlide ? 0 : -1}
                                onClick={() => openSignup(dispatch, useSSO)}
                                contained="primary"
                                loading={configIsLoading}
                            >
                                <FormattedMessage id="welcome_screen_signup" />
                            </SignBtn>
                            <DotsBox>
                                <PrevButton onClick={handleArrowClick(BACKWARD)} aria-label={BACK_ARROW_LABEL}>
                                    <ArrowIcon id="ic_arrow_left_m" title={BACK_ARROW_LABEL} />
                                </PrevButton>
                                {PAGES.map((page, index) => (
                                    <Dot
                                        key={index}
                                        $selected={currentSlide === index}
                                        tabIndex="0"
                                        onClick={() => setCurrentSlide(index)}
                                        aria-label={SLIDER_DOT_LABEL(index + 1)}
                                    />
                                ))}
                                <NextButton onClick={handleArrowClick(FORWARD)} aria-label={NEXT_ARROW_LABEL}>
                                    <ArrowIcon id="ic_arrow_right_m" title={NEXT_ARROW_LABEL} />
                                </NextButton>
                            </DotsBox>
                        </NavigationBox>
                    </Background>
                </Wrapper>
            )),
        [currentSlide, useSSO, configIsLoading],
    );

    const onTouchStart = e => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchMove = e => setTouchEnd(e.targetTouches[0].clientX);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > MIN_SWIPE_DISTANCE;
        const isRightSwipe = distance < -MIN_SWIPE_DISTANCE;
        if (isLeftSwipe) {
            const index = currentSlide === PAGES.length - 1 ? 0 : currentSlide + 1;
            setCurrentSlide(index);
        }
        if (isRightSwipe) {
            const index = currentSlide > 0 ? currentSlide - 1 : PAGES.length - 1;
            setCurrentSlide(index);
        }
    };

    const closeModal = useCallback(
        modal => () => {
            dispatch(closeModalRequest({ name: modal, meta: { name: modal } }));
        },
        [],
    );

    return (
        <>
            <StyledCarousel
                selectedItem={currentSlide}
                showThumbs={false}
                showStatus={false}
                showArrows={false}
                stopOnHover={false}
                showIndicators={false}
                swipeable={true}
                infiniteLoop={true}
                swipeScrollTolerance={MIN_SWIPE_DISTANCE}
                onSwipeMove={onTouchMove}
                onSwipeStart={onTouchStart}
                onSwipeEnd={onTouchEnd}
            >
                {getContent()}
            </StyledCarousel>
            {!isAuth && <CallMatomoUnauthorized />}
            {modal[REACTIVATION_MODAL] && <ReactivationModal handleClose={closeModal(REACTIVATION_MODAL)} />}
        </>
    );
};

export default Welcome;
